export const GPT4 = 'gpt4'
export const GPT4o = 'gpt-4o' // GPT-4o ("o" for "omni")
export const GPT4oMINI = 'gpt-4o-mini' // GPT-4o ("o" for "omni")
export const GPT3_5 = 'gpt3_5'
export const WRITE_SHIFT = 'write_shift'
export const NOUSRESEARCH_NOUS_CAPYBARA_7B = 'nousresearch_nous_capybara_7b'
export const MISTRALAI_MISTRAL_7B_INSTRUCT = 'mistralai_mistral_7b_instruct'
export const HUGGINGFCEH4_ZEPHYR_7B_BETA = 'huggingfaceh4_zephyr_7b_beta'
export const MYTHO_MIST_7B = 'mytho_mist_7b'
export const PSYFIGHTER_13B = 'psyfighter_13b'
export const PSYFIGHTER_13B_2 = 'psyfighter_13b_2'
export const NOUS_HERMES_LLAMA2_13B = 'nous_hermes_llama2_13b'
export const PHIND_CODELLAMA_34B = 'phind_codellama_34b'
export const MISTRAL_8X7B_INSTRUCT = 'mixtral_8x7b_instruct'
export const LLAMA_2_13B_CHAT = 'llama_2_13b_chat'
export const PALM_2_CHAT_BISON = 'palm_2_chat_bison'
export const PALM_2_CODECHAT_BISON = 'palm_2_codechat_bison'
export const PALM_2_CHAT_BISON_32K = 'palm_2_chat_bison_32k'
export const PALM_2_CODECHAT_BISON_32K = 'palm_2_codechat_bison_32k'
export const GEMINI_PRO = 'gemini_pro'
export const PPLX_70B_ONLINE = 'pplx_70b_online'
export const PPLX_7B_ONLINE = 'pplx_7b_online'
export const LLAMA_2_70B_CHAT = 'llama_2_70b_chat'
export const GPT3_5_TURBO_TRAINED = 'ft:gpt-3.5-turbo-1106:gec::8hDmcG6p'

export const initProcessCreator = () => ({
  order_no: 1,
  name: 'Task 1',
  template: '',
  variables: [],
  model_type: GPT4,
  model_settings: {
    checkLevel: '3',
    checkMode: 'FULL_CHECK',
  },
})
