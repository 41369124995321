<template>
  <div class="container">
    <b-card no-body>
      <b-card-header>
        <h4>{{ $t('ai-ide-module.response') }}</h4>
        <feather-icon :icon="showResponse ? 'MinusIcon' : 'PlusIcon'"
                      role="button"
                      size="25"
                      @click="showResponse = !showResponse"
        />
      </b-card-header>
      <b-card-body v-if="showResponse"
                   class="response-dashboard"
      >
        <div class="response-screen"
             :class="modelForm.response?.type === 'error' && 'bg-error'"
        >
          <p v-if="modelForm.response">
            <vue-json-pretty :data="modelForm.response" />
          </p>
          <p v-else>
            {{ $t('ai-ide-module.please-submit-text') }}
          </p>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardBody, BCardHeader } from 'bootstrap-vue'
import VueJsonPretty from 'vue-json-pretty'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueJsonPretty,
  },
  props: {
    modelForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showResponse: true,
    }
  },
}
</script>
<style>
.bg-error {
  background-color: #ff686aa3 !important;
}
</style>
