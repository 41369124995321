<template>
  <div class="">
    <step-editor :model-form="modelForm" />
  </div>
</template>
<script>

import StepEditor from './StepEditor.vue'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    StepEditor,
  },
  props: {
    modelForm: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss">
.response-dashboard {
   min-height:400px;
   margin:10px;
  .response-screen{
    background:rgb(184, 249, 185);
    padding: 30px;
    height:70vh;
    margin-bottom: 10px;
    overflow: auto;
  }

  .input-section{
    border:1px solid rgb(112, 239, 114);
    padding:10px;
  }

  .api-section{
    background:#fff;
    padding: 30px;

  }
}
</style>
